<template>
  <div class="kechengBox">

    <!-- <span>乐高设计活动</span> -->
    <!-- <el-card>
      <img src="../../assets/activity/lego.png" class="image" />
      <div style="padding: 14px">
        <div class="bottom clearfix">
          <el-button type="primary" round plain @click="join('lego')">立即参与</el-button>
        </div>
      </div>
    </el-card> -->

    <!-- <span>编程设计活动</span> -->
    <!-- <el-card>
      <img src="../../assets/activity/program.png" class="image" />
      <div style="padding: 14px">
        <div class="bottom clearfix">
          <el-button type="primary" round plain @click="join('program')">立即参与</el-button>
        </div>
      </div>
    </el-card> -->


    
    <el-card>
      <img src="../../assets/activity/kechuang.png" class="image" />
      <div style="padding: 14px">
        <!-- <span>科创设计活动</span> -->
        <div class="bottom clearfix">
          <el-button type="primary" round plain @click="gotoAnswer('AI创作普及赛')">立即参与</el-button>
        </div>
      </div>
    </el-card>

    <el-dialog title="编程设计活动" :visible.sync="centerDialogVisible" width="30%" center>
      <div class="kechengBox">
        <el-button type="warning" round plain @click="gotoAnswer('Scratch 初中组')">Scrach</el-button>
        <el-button type="danger" round plain @click="gotoAnswer('Python 初中组')">Python</el-button>
        <el-button type="primary" round plain @click="gotoAnswer('C++ 初中组')">C++</el-button>
      </div>
    </el-dialog>

    <el-dialog title="题型" :visible.sync="centerDialogVisible1" width="1200px" center>
      <h2 style="margin: 50px auto; font-weight: bold"></h2>
      <div class="jumbotron well">
        <h1>试卷列表</h1>
        <div style="margin-bottom: 68px">
          <div class="m_table">
            <div class="m_col first">
              <div>试题类型</div>
              <div></div>
              <div></div>
              <div></div>
              <div style="width: 200px">操作</div>
            </div>

            <!-- 为了让编程题在最后可以用order， -->
            <div class="m_col" :style="index == 'programming' ? 'order: 99' : ''" v-for="(item, index) in examState"
              v-bind:key="index">
              <div>{{ questionType[index] }}</div>
              <div></div>
              <div></div>
              <div></div>
              <!-- :disabled="!start" -->
              <el-button type="primary" size="medium" v-if="item == true" @click="gotoExam(index)">开始答题</el-button>
              <i class="el-icon-check" v-else style="color: #67c23a; font-weight: 800">已提交</i>
            </div>
          </div>
          <div style="width: 100%; font-weight: 800; font-size: 18px" v-if="!examState.programming">
            您已完成所有题目，请点击交卷
          </div>
        </div>
      </div>
      <div class="m_col">
        <el-button type="primary" class="jiaojuan" style="width: 400px; margin: 50px; padding: 10px" @click="loginOut"
          v-if="!examState.programming">交卷</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getTime } from "../../utils/utils";
export default {
  name: "kecheng",
  props: {
    state: Number,
    msg: String,
    dark: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      centerDialogVisible: false,
      centerDialogVisible1: false,
      examState: {},
      questionType: {
        choice: "单选题",
        judge: "判断题",
        multipleChoice: "多选题",
        fillBlank: "填空题",
        programming: "做答题",
      },
      start: true,
    };
  },
  methods: {
    async join(kecheng) {
      if (kecheng == "program") {
        this.centerDialogVisible = true;
        return;
      } else if (kecheng == "lego") {
        this.$alert(
          "乐高设计活动仅设线下决赛，具体时间请关注“宁夏青少年科技赛事”微信公众号",
          "提示",
          {
            confirmButtonText: "确定",
            callback: (action) => { },
          }
        );
      }
    },
    async gotoAnswer(kecheng) {
      //获取云端时间目前时间
      this.$localStorage.set("login_status_category", kecheng);

      let status = await getTime();

      //获取云端时间开始时间
      var db = this.$serverLess.database();
      const start_timeRaw = await db
        .collection("exam-support")
        .doc("8655d51b638050c901184a0d37280391")
        .get();
      let start_time = start_timeRaw.data[0].examStart;
      console.log(start_time);

      if (new Date(status.data) < start_time) {
        this.$alert(`比赛尚未开启，正式比赛时间${start_time}`, "提示", {
          confirmButtonText: "确定",
          callback: (action) => { },
        });
        return;
      } else {
        this.centerDialogVisible1 = true;
        this.choose();
      }
    },
    async choose(kecheng) {
      this.$http
        .post("/exam", {
          mode: "examStatusKCS",
          username: this.$localStorage.get("login_status_username"),
          password: this.$localStorage.get("login_status_token"),
          choiceMode:this.$localStorage.get("login_status_category"),
          examID: this.$localStorage.get("examID"),
        })
        .then((res) => {
          console.log("examStatusKCS", res);
          delete res.data.start;
          this.examState = res.data;

          // if (res.data.programming) {
          //   this.$router.push({ name: "fileMode", params: { mode: kecheng } });
          // } else {
          //   this.$alert("您的作品已经提交请勿重复提交！", "提示", {
          //     confirmButtonText: "确定",
          //   });
          // }
        })
        .catch((error) => {
          console.log(error);
          this.$alert("网络错误请联系管理员", "提示", {
            confirmButtonText: "确定",
            callback: (action) => { },
          });
        });
    },
    loginOut() {
      // this.$router.push({ name: "Challenge" }); //跳转页面

      this.$localStorage.remove("login_status_token");
      this.$localStorage.remove("login_status_username");
      this.$localStorage.remove("choice");
      this.$localStorage.remove("fillBlank");
      this.$localStorage.remove("judges");
      this.$localStorage.remove("programming");

      let url =
        process.env.NODE_ENV === "development"
          ? "http://localhost:8080/"
          : "https://yckcs.com";
      window.open(url, "_self"); //跳转页面
    },
    gotoExam(index) {
      console.log(index);
      this.$router.push({
        name: "fileMode",
        params: { examType: index },
      });
    },
  },
  mounted() {
    if (this.state == 3) this.centerDialogVisible1 = true;
  },
  computed: {},
  watch: {
    // state: function (newVal, oldVal) {
    //   console.log({ newVal });
    //   if (newVal == 3) this.centerDialogVisible1 = true;
    // },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.login-bottom {
  background: white;
  padding: 50px 0;
  width: 100%;
  text-align: center;
  margin-top: 50px;
  position: relative;
  z-index: 51;
}

.login-bottom p {
  line-height: 190%;
}

.dark {
  background: #1f1f1f;
  color: #78cae5;
}

.darkA {
  color: #78cae5;
}

.kechengBox {
  display: flex;
  width: 80%;
  justify-content: space-around;
  margin: 20px auto;
}

.image {
  width: 100%;
  display: block;
}

.el-card__body {
  width: 230px;
}

.el-card__body span {
  margin: 20px;
}

/* 答题页面 */
.m_table {
  display: flex;

  margin: 60px auto 28px auto;
  flex-wrap: wrap;
  width: 80%;
}

.m_col {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  width: 100%;
  margin-right: 15px;
  text-align: center;
}

.m_col div,
.m_col i {
  border-radius: 3px;
  width: 150px;
  color: black;
  background: #eee;
  margin-top: 10px;
  font-size: 17px;
  height: 48px;
  line-height: 48px;
}

.m_col i {
  width: 200px;
}

.m_col:first-child div {
  color: white;
  background: rgba(61, 129, 255, 0.9);
  margin-top: 10px;
  font-weight: 600;
}

.jumbotron.well {
  text-align: center;
}

.jumbotron.well.table {
  padding-bottom: 88px;
}

.m_col .el-button--primary,
.m_col .el-button--success {
  width: 200px;
  margin-top: 10px;
  height: 48px;
  padding: 0px;
}

.el-button {
  font-size: 17px !important;
}
</style>