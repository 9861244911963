<template>
  <div>
    <topBanner></topBanner>

    <div>
      <div class="row clearfix">
        <div class="progress">
          <div class="progress_containor" ref="p0">
            <div>01</div>
            <div>核对信息</div>
          </div>
          <div>———————</div>
          <div class="progress_containor" ref="p2">
            <div>02</div>
            <div>阅读考试规则</div>
          </div>
          <div>———————</div>
          <div class="progress_containor" ref="p3">
            <div>03</div>
            <div>开始作答</div>
          </div>
        </div>
        <!-- 核对信息 -->
        <div v-show="state == 0">
          <div class="jumbotron well" style="">
            <h1>考生信息</h1>
            <div class="information">
              <div class="form-class">
                <div class="form-name">姓名</div>
                <el-input
                  v-model="userInfo.name"
                  :readonly="true"
                  :disabled="true"
                ></el-input>
              </div>
              <div class="form-class">
                <div class="form-name">选手学校</div>
                <el-input
                  v-model="userInfo.school"
                  :readonly="true"
                  :disabled="true"
                ></el-input>
              </div>
              <div class="form-class">
                <div class="form-name">联系方式</div>
                <el-input
                  v-model="userInfo.username"
                  :readonly="true"
                  :disabled="true"
                ></el-input>
              </div>
              <div class="form-class">
                <div class="form-name">年级</div>
                <el-input
                  v-model="userInfo.grade"
                  :readonly="true"
                  :disabled="true"
                ></el-input>
              </div>
              <div class="form-class">
                <div class="form-name">班级</div>
                <el-input
                  v-model="userInfo.class"
                  :readonly="true"
                  :disabled="true"
                ></el-input>
              </div>
        
              <!-- <div class="form-class">
                <div class="form-name">所抽题目</div>
                <el-input
                  v-model="userInfo.subject"
                  :readonly="true"
                  :disabled="true"
                ></el-input>
              </div> -->
            </div>
          </div>
          <div class="row clearfix">
            <div class="col-md-5 column"></div>
            <div class="col-md-2 column">
              <el-button type="primary" @click="goto">信息已确认</el-button>

              <el-button type="warning" @click="errorMessage"
                >信息有误</el-button
              >
            </div>
            <div class="col-md-5 column"></div>
          </div>
        </div>
        <!-- 摄像头 -->

        <!-- 提示 -->
        <div v-show="state == 1">
          <div class="jumbotron well xuzhi">
            <h1>考生须知</h1>
            <p>
              1、请务必使用最新的Chrome浏览器访问，版本不低于83，保证电脑处于良好的网络环境，推荐下载及上传速度均高于512KB/s。
            </p>
            <p>2、登录系统后请核对个人信息，了解考试须知。</p>
            <p>
              3、请在规定时间完成作品创作，结束前提交比赛作品。考试时间结束时系统将会自动交卷，提交作品时间超过规定时间造成的后果自负。
            </p>
            <p>
              4、在考试过程中若因电源网络等问题造成中断，请尽快再次登录系统，最终参赛作品以最后一次提交的程序为准；
            </p>
            <p>
              5、在作答编程题时可以跳出系统并在本地编辑器制作作品，但是在作答完成后请作品以正确的方式提交，在提交时请注意代码格式及代码完整性；
            </p>
            <p>6、请严格按照比赛流程及要求操作，违反规则造成的后果自负。</p>
            <p>7、如有问题请联系赛事组陈老师18095490105</p>
          </div>
          <div class="row clearfix">
            <div class="col-md-5 column"></div>
            <div class="col-md-2 column">
              <button
                type="button"
                class="btn btn-primary btn-block btn-lg"
                @click="goto"
              >
                我已了解
              </button>
            </div>
            <div class="col-md-5 column"></div>
          </div>
        </div>
        <!-- 题目列表 -->
        <div v-show="state == 2 || state == 3">
          <h2 style="margin: 50px auto; font-weight: bold">
            <!-- {{ time }}您所作答的试卷类型为：{{ tttype[userInfo.category] }} -->
          </h2>
          <kecheng :state="my_state"></kecheng>
        </div>

        <!-- <div v-show="state == 3"></div> -->
      </div>
    </div>

    <canvas
      id="canvas"
      width="300px"
      height="300px"
      style="display: none"
    ></canvas>
  </div>
</template>
<script>
import topBanner from "../components/topBanner";
import { countDown, getUserMedia } from "../utils/utils.js";
import { Loading } from "element-ui";

import kecheng from "../components/kecheng/kecheng";

console.log(countDown);
export default {
  data() {
    return {
      textBoolean: true,
      noticeLists: [],
      nullimg: false,
      close: false,
      title: "",
      content: "",
      examType: "",
      userInfo: {
        restInfo: {},
      },
      tttype: {
        "Scratch 小初组（1-3年级）": "C卷",
        "Scratch 小高组（4-6年级）": "B卷",
        "Scratch 初中组": "A卷",
        "Python 小学组": "B卷",
        "Python 初中组": "A卷",
        "Python 高中组": "B卷",
        "Arduino 小学组": "C卷",
        "Arduino 初中组": "C卷",
        "C++ 初中组": "B卷",
        "C++ 高中组": "C卷",
      },
      /////////////////////
      state: 0,
      my_state: 0,
      ////////////////////试题
      questionType: {
        choice: "单选题",
        judge: "判断题",
        multipleChoice: "多选题",
        fillBlank: "填空题",
        programming: "做答题",
      },
      examState: {
        // choice: false,
        // fillBlank: false,
        // judges: false,
        // programming: false,
      }, //试题进度
      cameraState: false,
      ////////////////////
      time: "考试尚未开始",
      start: false,
    };
  },
  components: { topBanner, kecheng },
  methods: {
    goto() {
      // this.$router.push("/");
      this.state++;
      this.setColor(this.state);

      if (this.state === 1) {
        this.camera();
      }

      console.log("app", this.state, this.cameraState);

      //摄像头关闭开启
      // if (this.state === 2 && this.cameraState === false) {
      //   this.error();
      //   this.state--;
      // }
    },
    gotoExam(index) {
      // this.$http
      //   .post("/utils", {
      //     mode: "getDate",
      //   })
      //   .then((response) => {
      //     console.log(response);
      //   })
      //   .catch((error) => {
      //     console.log(error);
      //     this.tipWords = error;
      //     this.tips = true;
      //   });
      console.log(index);
      this.$router.push({
        name: "Examine",
        params: { examType: index },
      });
    },
    setColor(state) {
      // for (let index in 3) {
      //   this.$refs["p" + index].style.color = "#aaa";
      // }
      // ke chuangsai guanle
      // this.$refs["p" + state].style.color = "rgb(80, 141, 255)";
    },
    camera() {
      let video = document.getElementById("video");

      //成功回调
      var that = this;

      //失败回调
      //开启摄像头

      // getUserMedia({ video: { width: 500, height: 400 } }, success, this.error);

      function success(stream) {
        video.srcObject = stream;
        video.play();
        that.cameraState = true;
        that.$message({
          showClose: true,
          message: "摄像头检测成功",
          type: "success",
          duration: 2000,
        });

        let canvas = document.getElementById("canvas");
        let context = canvas.getContext("2d");
        setTimeout(() => {
          context.drawImage(video, 0, 0, 300, 300);

          let examID = that.$localStorage.get("examID");
          let username = that.$localStorage.get("login_status_username");

          canvas.toBlob((blobObj) => {
            that.$serverLess
              .uploadFile({
                // 云存储的路径  szs需要改/
                cloudPath: `online_work/${examID}_camera/${username}/login.png`,
                // 需要上传的文件，File 类型
                filePath: blobObj,
                //有问题this.fileList[i][0].raw
              })
              .then((res) => {
                // 返回文件 ID
                console.log(res.fileID);
                that.$http.post("/exam", {
                  mode: "camera",
                  username: that.$localStorage.get("login_status_username"),
                  cameraUrl: res.fileID,
                  examID: that.$localStorage.get("examID"),
                });
              })
              .then((res) => {
                console.log(res);
              });
            console.log(blobObj);
          });
        }, 100);
      }

      //上传
    },
    error(error) {
      console.log(error);
      this.cameraState = false;
      this.$alert("访问用户媒体失败，请查看下方提示并检查您的摄像头", "失败", {
        confirmButtonText: "确定",
        callback: (action) => {
          this.$message({
            showClose: true,
            message: "获取摄像头错误，请查看下方提示并检查您的摄像头",
            type: "error",
            duration: 600000,
          });
        },
      });
    },
    loginOut() {
      // this.gotoChallenge(); //不需要算分所以直接gotoChallenge
      // return;
      //还需要在云端检测字段 这个必须做！！！！
      // let account = this.$localStorage.get("steam_login_status").account;
      this.gotoMain();
      return;
      //下面是自动算分
      let loadingInstance = Loading.service();

      //还需要在云端检测字段 这个必须做！！！！
      this.$http
        .post("/exam", {
          mode: "calScore",
          username: this.$localStorage.get("login_status_username"),
          password: this.$localStorage.get("login_status_token"),
          examID: this.$localStorage.get("examID"),
        })
        .then((res) => {
          loadingInstance.close();
          //跟新进度假的，这里都是从网络获取
          console.log(res);
          if (res.data.status == "您已提交过本套题目") {
            this.$alert(`您已经提交过本题！`, "提示", {
              confirmButtonText: "确定",
              callback: (action) => {
                this.gotoChallenge();
              },
            });
          } else {
            let totalScore = res.data.score;

            this.$alert(
              `交卷成功，您的客观题成绩为：${totalScore}分！请继续加油哦！解析请点击下载：<a href='https://6e78-nxcodenet-10fd8f-1301115590.tcb.qcloud.la/challenge/exam/suyang/20220101/%E7%AD%94%E6%A1%88%E6%96%87%E4%BB%B6%E5%A4%B9.rar?sign=80fda9b284fe7bf84f7f09fe7272c132&t=1642001701'>附件下载</a>`,
              "交卷成功",
              {
                dangerouslyUseHTMLString: true,
                confirmButtonText: "确定",
                callback: (action) => {
                  this.gotoChallenge();
                },
              }
            );
          }

          // this.$router.push("/question/fillBlank");
        })
        .catch((err) => {
          loadingInstance.close();

          console.log(err);
          this.$alert(err, "提交失败", {
            confirmButtonText: "确定",
            type: "warning",
          });
        });
    },
    gotoMain() {
      // this.$router.push({ name: "Challenge" }); //跳转页面

      this.$localStorage.remove("login_status_token");
      this.$localStorage.remove("login_status_username");
      this.$localStorage.remove("choice");
      this.$localStorage.remove("fillBlank");
      this.$localStorage.remove("judges");
      this.$localStorage.remove("programming");

      let url =
        process.env.NODE_ENV === "development"
          ? "http://localhost:8080/"
          : "https://yckcs.com/#/";
      window.open(url, "_self"); //跳转页面
    },
    errorMessage() {
      this.$alert(
        `请联系赛事组，韩老师 18395284370，陈老师18095490105， 黄老师13469516491`,
        "信息有误",
        {
          confirmButtonText: "确定",
          callback: (action) => {},
        }
      );
    },
  },
  mounted() {
    //获取网络数据
    //从exam列表里examlist[examid:1130,score:duoshaofen]，查询是否考过试

    // 免刷新该标签进入考试
    // var db = this.$serverLess.database();
    // const watcher = db
    //   .collection("exam-support")
    //   .where({})
    //   .watch({
    //     onChange: (snapshot) => {
    //       let start = snapshot.docs[3].start;
    //       console.log(start);
    //       this.start = start;
    //       if (start == true) {
    //         this.time = "考试开始，请开始答题";
    //       } else {
    //         this.time = "考试尚未开始";
    //       }
    //     },
    //     onError: (err) => {
    //       console.error("the watch closed because of error", err);
    //     },
    //   });

    this.$http
      .post("/exam", {
        mode: "examStatus",
        username: this.$localStorage.get("login_status_username"),
        password: this.$localStorage.get("login_status_token"),
        choiceMode: this.$localStorage.get("login_status_category"),
        examID: this.$localStorage.get("examID"),
      })
      .then((res) => {
        console.log("examStatus", res);
        this.start = res.data.start; //先拿start
        delete res.data.start; //删除start，因为要渲染
        this.examState = res.data;

        for (let index in this.examState) {
        }

        if (this.start == true) this.time = "考试开始，请开始答题";
      })
      .catch((error) => {
        console.log(error);
        this.tipWords = error;
        this.tips = true;
      });

    let params = this.$route.params;
    // this.state=3
    if (params.id == 3) {
      this.state = 3;
    }

    // this.state = 3;让页面保持在第三页
    this.setColor(this.state);
    setTimeout(() => {
      this.userInfo = this.$localStorage.get("steam_userInfo");
      this.userInfo.username = this.$localStorage.get("login_status_username");
      this.userInfo.category = this.$localStorage.get("login_status_category");
      console.log(this.userInfo, 1111111111111);
      if (!this.userInfo) {
        this.$alert("登录信息为空请重新登录", "提示", {
          confirmButtonText: "确定",
          callback: (action) => {
            this.$router.push("/");
          },
        });
      }
      console.log(this.userInfo);
      //网页获取字段比较合理
    }, 0);
  },
  watch: {
    userInfo: function (newVal, oldVal) {
      this.$localStorage.set("my_subject",newVal.subject)
      newVal.subject = `第${newVal.subject}题`;
      return newVal;
    },
    // state: function (newVal, oldVal) {
    //   console.log({ newVal });
    //   if (newVal == 3) this.centerDialogVisible1 = true;
    // },
  },
};
</script>
<style scoped>
.container {
  /* height: 100vh; */
  margin-top: 100px;
  display: flex;
  align-items: center;
}
.jumbotron.well {
  padding: 5px 35px 35px 35px;
  /* margin: 0 auto; */
  background: white;
}

.progress,
.jumbotron {
  width: 1200px;
  /* padding: 0 !important; */
  margin: 20px auto;
}

h1 {
  font-size: 50px;
  margin: 39px;
}
p {
  text-align: left;
}
.btn {
  background: rgb(61, 169, 255);
}
.progress {
  margin-top: 50px;
  height: 100px;
  display: flex;
  color: #aaa;
  justify-content: space-around;
  align-items: center;
}
.progress_containor div:first-child {
  font-size: 39px;
}
.progress_containor div:nth-child(2) {
  font-size: 18px;
}
.progress_containor:first-child {
  margin-left: 30px;
}
.progress_containor:last-child {
  margin-right: 30px;
}
/* 信息填写 */
.information {
  margin: 80px auto;
  width: 100%;
}
.form-class {
  position: relative;
  margin: 20px auto;
  /* margin-bottom: 30px; */
  width: 440px;
  text-align: left;
  font-size: 16px;
  display: flex;
  align-items: center;
}
.form-class:first-child {
  margin-top: 39px;
}
.form-class:last-child {
  margin-bottom: 39px;
}
/* .form-class::before,
.el-collapse-item__header::before {
  content: " ";
  display: inline-block;
  width: 6px;
  height: 6px;
  margin: 2px;
  background-color: red;
  border-radius: 8px;
} */
.form-name {
  width: 150px;
  text-align: right;
  margin-right: 20px;
}
/* —————————————————————————————— */
/* ----------摄像头检测------------*/
.pop-video-main {
  background: #f7fbff;
  border: 1px solid #ddd;
  margin: 50px;
  padding: 20px;
  margin-bottom: 20px;
}
/* ------------------------------ */
p {
  padding: 0 20px;
}
video {
  margin: 70px auto 50px auto;
  background: #666;
}
.jumbotron .tips {
  font-size: 18px;
  color: #666;
  margin: 50px auto;
  text-align: center;
  width: 75%;
}

/* 考场须知 */
.xuzhi p {
  font-size: 18px;
  width: 80%;
  margin: 15px auto;
}
.xuzhi p:last-child {
  margin-bottom: 50px;
}

/* 答题页面 */
.m_table {
  display: flex;

  margin: 60px auto 28px auto;
  flex-wrap: wrap;
  width: 80%;
}

.m_col {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  width: 100%;
  margin-right: 15px;
}

.m_col div,
.m_col i {
  border-radius: 3px;
  width: 150px;
  color: black;
  background: #eee;
  margin-top: 10px;
  font-size: 17px;
  height: 48px;
  line-height: 48px;
}
.m_col i {
  width: 200px;
}
.m_col:first-child div {
  color: white;
  background: rgba(61, 129, 255, 0.9);
  margin-top: 10px;
  font-weight: 600;
}

.jumbotron.well.table {
  padding-bottom: 88px;
}
.m_col .el-button--primary,
.m_col .el-button--success {
  width: 200px;
  margin-top: 10px;
  height: 48px;
  padding: 0px;
}
.el-button {
  font-size: 17px !important;
}
</style>

<style>
span {
  font-size: 17px !important;
}
.row {
  margin: 0;
}
</style>
